<template>
  <v-container>
    <user-table :loading="loading" :agent_data="agent_data"></user-table>
  </v-container>
</template>

<script>
import UserTable from "../../components/Admin/UserTable.vue";

import gql from "graphql-tag";

export default {
  name: "AgentTable",
  components: { UserTable },

  data: () => ({
    loading: false,
    agent_data: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.loading = true;
      const { data } = await this.$apollo.query({
        query: gql`
          query AdminUsers {
            users {
              acl
              active
              approved
              all_in
              annuity_premium
              allowed_carriers_aggregate {
                aggregate {
                  count
                }
              }
              campaigns_aggregate(where: { status: { _neq: "created" } }) {
                aggregate {
                  count
                }
              }
              purchases(
                where: {
                  _and: { status: { _neq: "created" }, type: { _eq: "OTO" } }
                }
              ) {
                id
                created_at
              }
              campaign_cache
              phone
              first_name
              hidden
              status
              marketer {
                first_name
                last_name
              }
              last_name
              id
              created
              seminar_control_training
              street
              city
              state
              zip
              email
              software_expiry
              source
            }
          }
        `,
      });

      this.agent_data = data.users;

      this.agent_data.forEach((item) => {
        item.marketer_clean = item.marketer
          ? `${item.marketer.first_name} ${item.marketer.last_name}`
          : "";
        item.allowed_carriers_cnt =
          item.allowed_carriers_aggregate.aggregate.count;
        item.campaign_cache = item.campaigns_aggregate.aggregate.count;
        item.name = `${item.first_name} ${item.last_name}`;
      });
      this.loading = false;
    },
  },
};
</script>
